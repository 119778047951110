import { getSignupParams, normalizeQueryParams } from '~/domains/restaurants/utils/restaurantParams'
import { useGetUserProfile } from '~/domains/users/queries'

export default defineNuxtRouteMiddleware(async (from) => {
  const localePath = useLocalePath()
  const normalizedParams = computed(() => normalizeQueryParams(from.query))
  const params = getSignupParams({ restaurant: normalizedParams.value, plan: normalizedParams.value.plan || undefined })

  const { data: user, suspense } = useGetUserProfile()
  await suspense()

  if (!user.value) {
    return await navigateTo(localePath('/login'), {
      replace: true,
    })
  }

  if (!user.value.firstName || !user.value.lastName) {
    return await navigateTo(localePath(`/auth/create-profile?${params}`), {
      replace: true,
    })
  }
})
